<template>
<div>
    <div style="font-size:10px;background-color:white;margin:30px" id="printInvoice">
        <div class="w-100 t-c" style="font-size: 14px;">INVOICE</div>
        <!--Company Details , Invoice Details-->
        <div class="w-100">
            <div class="">
                <div class="w-50 height-150 panel-default float-left">
                    <div class="panel-body w-50 float-left">
                        <img :src="companyuser.photo" alt="" width="200" height="125" style="margin-left:10px;"><br>
                    </div>
                    <div class="panel-body w-50 float-left" v-if="companyuser!=null" style="font-size:12px">
                        
                        <strong>{{companyuser.name}}</strong><br>
                        
                        <div v-for="(address) in companyuser.addressess" v-bind:key="address.id">
                            {{address.street}},{{address.city}},Uttar Pradesh
                        </div>
                            {{companyuser.email}}<br>
                            GST: {{companyuser.gst}}<br>
                        <div v-for="(mobile) in companyuser.mobiles" v-bind:key="mobile.id">
                            {{mobile.mobile}}
                        </div>

                    </div>
                </div>
                <div class="w-50 height-150 panel-default float-left">
                    <div class="">
                        <div class="w-50 height-75 panel-default float-left">
                            <div class="panel-body">
                                <strong>Invoice No. </strong><br>
                            <span v-if="transaction!=null && transaction.isinvoice==1">{{transaction.usersequence}}</span>
                            <!--<span v-else style="color:#2449cd;">Challan</span>--> 
                            </div>
                        </div>
                        <div class="w-50 height-75 panel-default float-left">
                            <div class="panel-body">
                                <strong>Invoice Date </strong><br>
                                {{moment(transaction.created_at).format('DD-MM-YYYY')}}
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="w-50 height-75 panel-default float-left">
                            <div class="panel-body">
                                <strong>Place Of Supply</strong><br>
                                Kanpur
                            </div>
                        </div>
                        <div class="w-50 height-75 float-left">
                            <div class="panel-body">
                                <strong v-if="loggedinuser.companyid!=7">Due Date</strong><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Customer Details-->
        <div class="w-100">
            <div class="">
                <div class="w-50 height-150 panel-default float-left">
                    <div class="panel-body" style="font-size:12px">
                        <strong><u>Buyer</u></strong><br>
                        {{transaction.user.name}}<br>
                        <div v-for="(address) in transaction.user.addressess" v-bind:key="address.id">
                            {{address.houseno}}
                            {{address.street}},{{address.city}},Uttar Pradesh
                        </div> 
                            GST         : {{transaction.user.gst}}<br>
                            PAN No      : {{transaction.user.pan}}<br>
                        <span v-for="(mobile) in transaction.user.mobiles" v-bind:key="mobile.id">
                            {{mobile.mobile}},
                        </span>
                    </div>
                </div>
                <div class="w-50 height-150 panel-default float-left">
                        <div class="w-50 height-150 panel-default float-left">
                            <div class="panel-body" >
                            <strong>Destination</strong>  <br>
                            </div>
                        </div>
                        <div class="w-50 height-150 float-left">
                            <div class="panel-body" v-if="loggedinuser.companyid!=7">
                                <strong> Supplier's Ref. :</strong><br>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <!--Items Display-->
        <div class="panel-default height-700">
            <table class="table" id="printtable">
                <thead>
                    <tr>
                        <th style="width:5%">Sr no</th>
                        <th class="w-25">Description of Goods</th>
                        <th>Serial no.</th>
                        <th>HSN</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Excluding Tax</th>
                        <th>Taxes</th>
                        <th>Net Price</th>
                    </tr>
                </thead>
                    <tr style="font-size:12px;" v-for="(item,index) in this.transactiondetails" :key="item.id">
                        <td>{{ index+1 }}.</td>
                        <td style="text-align:left">
                            <span v-if="item.product!=null && item.product?.attribute!=null">
                                <div v-for="company in item.product?.attribute" :key="company.id">
                                    {{ getcompany(company.attribute_value_ids) }} 
                                </div>
                            </span>
                            {{ item.product?.category.name }} {{ item.product?.name }} 
                        </td>
                        <td> 
                            <div v-for="(barcodeitem) in item.barcodes" v-bind:key="barcodeitem.id">
                                {{ barcodeitem.barcode  }}
                            </div>
                        </td>
                        <td>
                            {{item?.product?.hsn}}
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td><i class='bx bx-rupee'></i> {{ item.price }}/pcs</td>
                        <td><i class='bx bx-rupee'></i> {{ item.netpricewithouttax }}</td>
                        <td>
                            <div v-for="(taxitem) in item.collectedtaxes" v-bind:key="taxitem.id" >
                                <div><strong>{{ getMasterLabelByType(taxitem.labelid,MASTER_TAXES) }}-
                                </strong>&nbsp;{{ taxitem.percent }}% ({{ taxitem.value1 }})</div>
                            </div>
                        </td>
                        <td><i class='bx bx-rupee'></i> {{ item.finalamount }}</td>
                    </tr>
            </table>
            </div>
        <div class="w-100 panel-default height-auto" style="margin-top:10pz">

            <div class="col-md-12 text-right"> 
                <div class="col-md-8 float-left" style="padding-top:14px;font-size:14px">Total Qty: <strong>{{ totalquantity }}</strong></div>
                <div class="col-md-4 float-left">
                    <table class="table" id="printtable">
                        <tr >
                            <td style="padding-top:10px"><strong>Amt.Exl Tax</strong></td>
                            <td style="padding-top:10px"><strong>{{transaction.amountwithouttax}}</strong></td>
                        </tr>
                        <tr v-for="item in hsnbasedtaxes" :key="item.id">
                            <td><strong>{{ getMasterLabelByType(item.labelid,MASTER_TAXES)}} :</strong></td>
                            <td><i class='bx bx-rupee'></i> {{item.value1 }}</td>
                        </tr>
                        <tr>
                            <td><strong>Discount:</strong></td>
                            <td><i class='bx bx-rupee'></i> {{transaction.discount}}</td>
                        </tr>
                        <tr>
                            <td><strong>Round off:</strong></td>
                            <td><i class='bx bx-rupee'></i> {{transaction.roundoff}}</td>
                        </tr>
                        <tr>
                            <td><strong>Total:</strong></td>
                            <td><strong><i class='bx bx-rupee'></i> {{transaction.amtafterdiscount}}</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
            <!--<div class="col-md-12 text-right" style="font-size:10px;"> <strong></strong></div>
            <div class="col-md-12 text-right" style="font-size:10px;" v-for="item in hsnbasedtaxes" :key="item.id">{{ getMasterLabelByType(item.labelid,MASTER_TAXES)}} : {{item.value1 }}</div>
            
            <div class="col-md-12 text-right" style="font-size:10px;">Discount:<strong>{{transaction.discount}}</strong></div>
            <div class="col-md-12 text-right" style="font-size:10px;">Total:<strong style="font-size:11px;">{{transaction.amtafterdiscount}}</strong></div>-->
        </div>
        
        <!--Bank Details-->
        <div class="w-100">
            <div class="">
                <div class="w-65 panel-body height-150 panel-default float-left">
                    <div class="" v-if="companyuser!=null" style="font-size:12px">
                        <strong><u>Bank Details:</u></strong><br>
                            <strong>Bank Name :</strong> HDFC BANK<br>
                            <strong> Account No:</strong> 50200052484219 <br>
                            <strong> IFSC Code :</strong> HDFC0009676<br>
                            <strong>Branch    :</strong>  THE MALL, PPN MARKET, KANPUR-208001,<br>
                                UTTAR PRADESH
                    </div>
                </div>
                <div class="panel-body w-35 height-150 panel-default float-left">
                    <div class="">
                        <div class="">
                            <strong>Pay Using UPI: </strong>
                        </div>
                    </div>
                    <div class="w-auto">
                        <div class=" ">
                            <div class="text-center"><img src="../../assets/vsdigibarcode.jpeg" height="100" width="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Terms And Condition-->
        <div class="w-100 height-150">
            <div class="w-25 panel-body height-150 panel-default float-left ">
                <div class="" style="font-size:12px">
                    <strong>Notes :</strong><br>
                    Thank You For the Business {{ transactiondetails[1]?.product?.servicecenter }}
                </div>
                <div class="panel-body">
                    <strong style="font-size:12px"><u> Service Center Details :</u></strong>    
                    <div v-for="item in transactiondetails" :key="item.id">
                        <li v-if="item?.product?.servicecenter!=null"><span  style="font-size:12px">{{item?.product?.servicecenter}}</span></li>
                    </div>
                </div>
            </div>
            <div class="w-50 height-150 panel-default float-left">
                <div class="height-auto" style="padding:18px;font-size:12px">
                    <strong>Terms and Conditions: </strong>
                    <ul>
                        <li >Goods once sold will not be taken back or exchanged</li>
                        <li >We are not responsible for any loss or damage of goods in transit</li>
                        <li >If not paid by due date will attract 24% interest</li>
                        <li >All disputes are subject to Kanpur jurisdiction only & no credit ingibe unless given in written</li>
                    </ul>
                </div>
            </div>
            <div class="w-25 panel-body height-150 text-center panel-default float-left">
                <div class="">
                    <strong>{{companyuser.name}}</strong>
                </div>
                <div class="flex-center-row" style="height: 6rem;">
                    <img src="../../assets/vsdigisignature.png" width="100" height="50"> 
                </div>
                <div class="">
                    <strong>Authorised Signature</strong> 
                </div>
            </div>
        </div> 
    </div>
        <!--Go Back Button-->
        <div class="col-md-12 row">
            <div class="col-md-4">
                <a href="#"  @click="back()">Go Back</a>
            </div>
            <div class="col-md-2 text-center">
                <a href="#"  @click="saveaspdf()">Save Pdf</a>
            </div>
             <div class="col-md-4 text-right">
                <a href="#"  @click="print()">Print</a>
            </div>
         </div>
</div>  
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins:[Constants],
    data() {
        return {
            moment:moment,
            pdfid:[]
        }
    },
    mounted() {
       this.$store.dispatch("fetchattributes")
    },
    components:{},
    computed:{
		transactions(){
			return this.$store.state.stransactions;
		},
        collectedtax(){
			return this.transaction.collectedtax
		},
        ...mapGetters([
			'viewno','transactiondetails','transaction','loggedinuser','companyuser',
            'attributes'
		]),
        
        totalquantity(){
			let total = 0
			this.transactiondetails.forEach(function(item){
				total = parseFloat(total) + parseFloat(item.quantity)
			})
			return total
		},
        hsnbasedtaxes(){
            let hsnbasedtaxes = [];
				this.transactiondetails.forEach(function(item){
					if(item.collectedtaxes!=null){
					item.collectedtaxes.forEach(function(taxitem){

						let found = hsnbasedtaxes.find(block=>{
							return block.labelid==taxitem.labelid && block.hsn==item.hsn
						})
						if(typeof found!=='undefined'){
							found.value1 = parseFloat(found.value1) + parseFloat(taxitem.value1)
							let foundIndex = hsnbasedtaxes.findIndex(block=>{
								return block.labelid==taxitem.labelid && block.hsn==item.hsn
							})
							found.value1 = found.value1.toFixed(2)
							hsnbasedtaxes.splice(foundIndex,1,found)
						} else {
							let obj = {hsn:item.hsn,labelid:taxitem.labelid,value1:taxitem.value1,percent:taxitem.percent}
							hsnbasedtaxes.push(obj)
						}
					})
					}
				})
            return hsnbasedtaxes
        }
	},
    methods: {
        saveaspdf(){
            this.$store.commit('assignloadingstatus',1)
            let payload={transactionid:this.transaction.id}
            window.axios.post('api/download/pdf',payload)
                .then(response=>this.processSequenceResponse(response.data))
                .catch(error=>console.log(error));
		      
        },
        processSequenceResponse(data){
            let payload={id:data.uploadpdfid}
            this.pdfid=data
            window.axios.post('api/fetch/download/pdf',payload)
                    .then(response=>this.processFetchPdfResponse(response.data))
                    .catch(error=>console.log(error));
        },
        processFetchPdfResponse(data1){
            if(data1.isdownload==1){

                let payload={id:this.pdfid.uploadpdfid}
                window.axios.post('api/download/pdf/updatedeleted',payload)
                    .then(response=>this.processFetchtruePdfResponse(response.data))
                    .catch(error=>console.log(error));
                console.log("Pdf downloaded :"+this.pdfid.uploadpdfid)
                this.$store.commit('assignloadingstatus',0)
                // window.open(data1.filename, '_blank');
                window.location.href=data1.filename
                var fileURL = window.URL.createObjectURL(new Blob(data1.filename));
                console.log("fileurl :"+fileURL)
				var docUrl = document.createElement('x');
                docUrl.href =fileURL ;
                docUrl.setAttribute('download', 'file.pdf');
                document.body.appendChild(docUrl);
                docUrl.click();
            }else{
                setTimeout(() => {
                    console.log("not found")
					console.log("Pdf Id"+this.pdfid.uploadpdfid)
                    this.processCheckSequenceResponse(this.pdfid);
                }, 6000);
            }
        },
		processCheckSequenceResponse(data){
            let payload={id:data.uploadpdfid}
            // this.pdfid=data
            window.axios.post('api/fetch/download/pdf',payload)
                    .then(response=>this.processFetchPdfResponse(response.data))
                    .catch(error=>console.log(error));
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        back(){
            //this.$router.push({name:"viewsales"})
            this.$store.commit("assignviewno",1)
        },
        getname(id){
			let obj = this.$store.state.products.find(o=>o.id==id);
			if(obj!=null){
				return obj.name;
			} else {
				return id;
			}
		},
        print(){
            const prtHtml = document.getElementById('printInvoice').innerHTML;

			// Get all stylesheets HTML
			let stylesHtml = '';
			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
			stylesHtml += node.outerHTML;
			}
            // Open the print window
			const WinPrint = window.open('', '', `left=20px,right=20px,top=0,width=90%,height=auto,toolbar=0,scrollbars=0,status=0 `);

			WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
				${stylesHtml}
			</head>
			<body style="margin:40px">
				${prtHtml}
			</body>
			</html>`);
			// WinPrint.document.close();
			// WinPrint.focus();
			// WinPrint.print();
			// WinPrint.close();
        }
    },
   
}
</script>
<style scoped>
#printtable > tr > td {
    font-size: 12px;
    font-weight: 500;
    color:black;
    text-align: center;
}
#printtable {
    page-break-inside: auto;
}
#printtable > tr {
    page-break-inside: avoid;
    page-break-after: auto;
}
#printtable td{
    padding: 0;
    border-top: none;
}

#printtable > thead {
    display: table-header-group;
}

#printtable > thead > tr > th{
    font-size:11px;
    background: transparent;
    color:black;
    font-weight: 700;
    border-bottom: 1px solid black;
    letter-spacing: 0.5px;
    text-align: center;
}
.w-auto{
    width: auto;
}
.w-100{
    width: 100%;
}
.w-75{
    width: 75%;
}
.w-65{
    width: 65%;
}
.w-50{
    width: 50%;
}
.w-35{
    width: 35%;
}
.w-30{
    width: 30%;
}
.w-25{
    width: 25%;
}
.t-c{
    text-align: center;
}
.height-auto{
    height:auto;
}
.height-700{
    height:700px;
}
.height-500{
    height:500px;
}
.height-610{
    height:610px;
}
.height-175{
    height:175px;
}
.height-150{
    height:150px;
}
.height-125{
    height:125px;
}
.height-100{
    height:100px;
}
.height-85{
    height:85px;
}
.height-75{
    height:75PX;
}
.height-65{
    height:65PX;
}
.height-50{
    height:50px;
}
.panel-body{
    padding: 9px;
}
.panel-default {
    border-color: rgb(85, 85, 85);
    border:1px solid;
}
.float-left{
    float: left;
}

</style>